import Head from "next/head";

const Seo = ({ title, description, image }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || "https://www.usepinto.com/default-screenshot.png"} />
      <meta property="og:url" content={typeof window !== "undefined" && window.location.href} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || "https://www.usepinto.com/default-screenshot.png"} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="canonical" href={typeof window !== "undefined" && window.location.href} />
    </Head>
  );
};

export default Seo;
