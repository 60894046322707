import { useEffect, useState } from "react";

export default function ModalForm({ isOpen, onClose }) {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [animationClass, setAnimationClass] = useState("");
  const [formData, setFormData] = useState({
    ownerName: "",
    businessName: "",
    email: "", 
    phone: ""
  });

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setAnimationClass("modal-enter");
      document.body.style.overflow = "hidden";
    } else {
      setAnimationClass("modal-exit");
      setTimeout(() => setIsVisible(false), 170);
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const currentPage = typeof window !== 'undefined' ? window.location.href : 'Unknown Page';
      
      const response = await fetch('/api/sendToSlack', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'contact',
          name: `${formData.ownerName} (${formData.businessName})`,
          email: formData.email,
          message: `Phone: ${formData.phone}\nSubmitted from: ${currentPage}`
        }),
      });

      if (response.ok) {
        onClose();
      }
    } catch (error) {
      console.error('Error sending to Slack:', error);
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
      onClick={handleBackgroundClick}
    >
      <div
        className={`relative bg-white rounded-lg p-8 max-w-lg mx-auto shadow-lg w-full ${animationClass}`}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
        <h2 className="text-4xl font-semibold text-center mb-4 text-gray-800">
          Contact Us
        </h2>
        <p className="text-center text-gray-600 mb-6">
          Fill out the form below, and one of our specialists will get in touch
          with you shortly.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Owner Name
            </label>
            <input
              type="text"
              name="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              placeholder="Enter your full name"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Business Name
            </label>
            <input
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              placeholder="Enter your business name"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="youremail@example.com"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="(XXX) XXX-XXXX"
              className="mt-1 px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-200"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 bg-[#62a898] hover:bg-[#559486] text-white font-semibold rounded-md shadow-lg transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
